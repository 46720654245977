import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Link } from "gatsby"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import ButtonLink from "../components/ButtonLink"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found" />
    <PageContentContainer>
      <BannerContainer className="py-20">
        <div className="grid  justify-items-center gap-y-10">
          <h1>404 PAGE NOT FOUND</h1>
          <p className="text-2xl">
            Sorry, the page you're looking for doesn&#39;t exist.
          </p>
          <ButtonLink
            text="Go to Home Page"
            className="px-6 py-1 mt-20 text-xl md:text-2xl uppercase tracking-wide"
            to="/"
          />
        </div>
      </BannerContainer>
    </PageContentContainer>
  </Layout>
)

export default NotFoundPage
